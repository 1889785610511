import React from "react";
import Login from "./pages/login";
import Skills from "./pages/skills";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { PrivateRoute, ProvideAuth } from "./auth";

export default function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#079605'
      },
    },
  })
  return (
    <ProvideAuth>
      <ThemeProvider theme={theme}>
        <Router>
            <Switch>
              <PrivateRoute path="/skills">
                <Skills />
              </PrivateRoute>
              <Route path="/">
                <Login />
              </Route>
            </Switch>
        </Router>
      </ThemeProvider>
    </ProvideAuth>
  );
}