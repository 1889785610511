import { Backdrop, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import AddIcon from '@material-ui/icons/Add';
import { useAuth } from "../../auth";
import './style.css';
import moment from "moment";
const axios = require('axios');
const baseUrl = 'https://admin-api.atomiccstudio.com/api';

export default function Skills(props) {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [skillsSelected, setSkillsSelected] = useState([]);
  const [skills, setSkills] = useState([]);
  const [userSkills, setUserSkills] = useState([]);
  const [lastUpdate, setLastUpdate] = useState('');
  const [open, setOpen] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [quadrantSelected, setQuadrant] = useState(null)
  const [quadrants] = useState([
    {
      prof: 1,
      label: ['Experiência Pessoal', 'Pouco Conhecimento']
    },
    {
      prof: 2,
      label: ['Experiência Profissional', 'Pouco Conhecimento']
    },
    {
      prof: 3,
      label: ['Experiência Pessoal', 'Bom Conhecimento']
    },
    {
      prof: 4,
      label: ['Experiência Profissional', 'Bom Conhecimento']
    }
  ]);

  const headerConfig = useCallback(() => {
    return {
      'appname': 'teams',
      'Authorization': `Bearer ${user.token}`
    }
  }, [user])

  const handleCloseModal = useCallback(() => {
    setOpen(false)
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpen(true)
  }, []);

  const handleCloseLoading = useCallback(() => {
    setOpenLoading(false)
  }, []);

  const handleOpenLoading = useCallback(() => {
    setOpenLoading(true)
  }, []);

  const getSkills = useCallback(async () => {
    handleOpenLoading()
    const response = await axios({
      method: 'get',
      url: `${baseUrl}/skills/${user.eid}`,
      headers: headerConfig()
    })
    setLastUpdate(response.data.updatedSkillsAt)
    setSkills(response.data.globalSkills)
    setUserSkills(response.data.mineSkills)
    handleCloseLoading()
  }, [handleCloseLoading, handleOpenLoading, headerConfig, user.eid])

  const updateSkills = useCallback(async () => {
    try {
      handleOpenLoading();
      const newSkills = [...userSkills.filter(userSkill => userSkill.prof !== quadrantSelected.prof), ...skillsSelected]
      await axios({
        method: 'put',
        url: `${baseUrl}/skills/${user.eid}`,
        headers: headerConfig(),
        data: { skills: newSkills }
      })
      setUserSkills(newSkills);
      setSkillsSelected([]);
      handleCloseModal();
      handleCloseLoading();
    } catch (err) {
      console.log(err)
    }
  },[setUserSkills, setSkillsSelected, handleCloseModal, handleCloseLoading, handleOpenLoading, headerConfig, user.eid, skillsSelected, userSkills, quadrantSelected])

  const addNewSkill = () => {
    setSkills([...skills, searchTerm.trim()])
    setSearchTerm('');
  }

  const isSkillSelected = useCallback((skill) => {
    return !!skillsSelected.filter(skillSelected => skillSelected.skill === skill).length
  },[skillsSelected])

  const selectSkill = useCallback((skill) => {
    setSkillsSelected([...skillsSelected, { skill, prof:quadrantSelected.prof }])
  },[skillsSelected, quadrantSelected, setSkillsSelected])

  const unselectSkill = useCallback((skill) => {
    setSkillsSelected([...skillsSelected.filter(skillSelected => skillSelected.skill !== skill)])
  },[skillsSelected, setSkillsSelected])

  const updateSkillsSelected = useCallback((quadrant) => {
    const skillsUserByQuadrant = userSkills.filter(userSkill => userSkill.prof === quadrant.prof);
    setSkillsSelected(skillsUserByQuadrant)
  }, [setSkillsSelected, userSkills])

  const skillsAvailable = useCallback(() => {
    return skills.filter(skill => {
      const isSearch = skill.toLowerCase().includes(searchTerm.toLowerCase());
      const isAvailable = !userSkills.find(userSkill => {
        return quadrantSelected && userSkill.skill === skill && userSkill.prof !== quadrantSelected.prof
      });
      return isSearch && isAvailable
    })
  },[skills,searchTerm, userSkills, quadrantSelected])

  const isAddSkill = useCallback(() => {
    return skills.filter(skill => skill.toLowerCase().includes(searchTerm.toLowerCase()))
  },[skills,searchTerm])


  useEffect(() => {
    getSkills();
  }, [getSkills])

  const renderSkillSelected = useCallback((prof) => {
    const skillsQuadrant = userSkills.filter(skill => skill.prof === prof);
    if (skillsQuadrant.length) {
      return (
        skillsQuadrant.map((skill,index) => <Chip key={index} label={skill.skill} className="skills__quadrants__chip" />)
      )
    } else {
      return (<span className="skills__quadrants__quadrant__label">Clique e escolha suas skills!</span>)
    }
  }, [userSkills]);

  return (
    <div className="skills__container flex">
      <Backdrop className="backdrop" open={openLoading} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="skills__header flex">
        <img src="logo-studio.svg" alt="Logo Atomic Studio" className="skills__logo" />
        <div className="skills__user flex">
          <h2 className="skills__user-name">{user.name}</h2>
          <p className="skills__user-dateUpdate">Última atualização: {moment(lastUpdate).format('DD/MM/YYYY')}</p>
        </div>
      </div>
      <div className="skills__content flex">
        <div className="skills__quadrants">
          <div className="skills__quadrants__arrow --right"> </div>
          <div className="skills__quadrants__arrow --top"> </div>
          <div className="skills__quadrants__content"></div>
          {
            quadrants.map((quadrant, index) => (
              <div
                key={index}
                onClick={() => {
                  setQuadrant(quadrant);
                  updateSkillsSelected(quadrant)
                  handleOpenModal();
                }}
                className={`skills__quadrants__quadrant --p${quadrant.prof}`}
              >
                <div className="skills__quadrants__content">
                  {renderSkillSelected(quadrant.prof)}
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <Dialog open={open} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Skills</DialogTitle>
        <DialogContent>
          {
            quadrantSelected &&
            <DialogContentText>
              Clique e selecione as tecnologias que voce tem <strong>{quadrantSelected.label[0]}</strong> e <strong>{quadrantSelected.label[1]}</strong>!
            </DialogContentText>
          }
          <div className="skills__modal__add flex">
            <TextField autoFocus margin="dense" id="search" label="Search" type="text" value={searchTerm} onChange={(event) => setSearchTerm(event.target.value.replace(/[,-]/g,''))} helperText="*Adicione uma tecnologia por vez"/>
            {!isAddSkill().length && <Button variant="contained" onClick={addNewSkill} color="primary" startIcon={<AddIcon />}> Adicionar </Button>}
          </div>
          <div className="skills__modal">
            {skillsAvailable().map((skill, index) => (
              isSkillSelected(skill) ?
                <Chip
                  key={index}
                  size="small"
                  label={skill}
                  color="primary"
                  onClick={() => selectSkill(skill)}
                  onDelete={() => unselectSkill(skill)}
                  className="skills__modal__chip"
                />
                :
                <Chip
                  key={index}
                  size="small"
                  label={skill}
                  color="default"
                  onClick={() => selectSkill(skill)}
                  className="skills__modal__chip"
                />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={updateSkills} color="primary"> Salvar </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}