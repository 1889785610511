import { Backdrop, Button, CircularProgress, TextField, Snackbar } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../auth";
import "./style.css"
export default function Login (props){
  const [open, setOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [messageAlert, setMessageAlert] = useState('')
  const [eid, setEID] = useState('')
  const [pid, setPID] = useState('')
  const auth = useAuth();
  let history = useHistory();

  const handleSubmit = async () => {
    setOpen(true)
    auth.signin({eid, pid}, () => {
      setOpen(false)
      history.push('/skills')
    }, (errorMessage) => {
      setOpen(false)
      setOpenAlert(true)
      setMessageAlert(errorMessage)
    })
  }

  const handleClose = () => {
    setOpenAlert(false)
  }

  return (
    <div className="login__container flex">
      <img className="login__logo" src="logo-studio.svg"/>
      <form className="login__form">
        <div className="login__form-input">
          <TextField id="standard-basic" label="EID" value={eid} onChange={(event) => setEID(event.target.value)} />
        </div>
        <div className="login__form-input">
          <TextField id="standard-basic" label="PID" value={pid} onChange={(event) => setPID(event.target.value)} />
        </div>
        <div className="login__form-btn">
          <Button variant="contained" onClick={handleSubmit} color="primary"> Login </Button>
        </div>
      </form>
      <Backdrop className="backdrop" open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {messageAlert}
        </Alert>
      </Snackbar>
    </div>
  )
}