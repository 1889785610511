import React, { createContext, useContext, useState } from "react";
import { Redirect, Route } from "react-router-dom";
const axios = require('axios');
const authContext = createContext();
const baseUrl = 'https://admin-api.atomiccstudio.com/api';

export function useProvideAuth() {
  const [user, setUser] = useState(null);
  const signin = async (params,cb, cbError) => {
    try {
      const response = await axios.post(`${baseUrl}/teams/login`, params)
      console.log(response.data)
      setUser(response.data);
    } catch (err) {
      return cbError(err.response.data.message);
    }
    return cb();
  };

  const signout = cb => {
    setUser(null);
  };

  return {
    user,
    signin,
    signout
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

export function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth?.user?.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}